<template>
  <div id="app">
    <div class="section-content">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div v-if="submitted" class="alert alert-success" role="alert">
              Thank you! We'll be in touch soon.
            </div>
            <div v-if="error" class="alert alert-danger" role="alert">
              Sorry! There was an error submitting the form. Either try again, or email <a href="mailto:greg@therouxplumbing.com">greg@therouxplumbing.com</a> directly. 
            </div>
          </div>
        </div>
      </div>
      <div v-if="!submitted" class="container">
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <div :class="{ 'form-group': true, 'has-error': !nameValid }">
              <label for="name">Your Name (required)</label>
              <input
                type="text"
                class="form-control"
                id="name"
                v-model="name"
              />
            </div>
          </div>

          <div class="col-sm-12 col-md-4">
            <div :class="{ 'form-group': true, 'has-error': !emailValid }">
              <label for="email">Your Email (required)</label>
              <input
                type="email"
                class="form-control"
                id="email"
                v-model="email"
              />
            </div>
          </div>

          <div class="col-sm-12 col-md-4">
            <div class="form-group">
              <label for="subject">Subject</label>
              <input
                type="text"
                class="form-control"
                id="subject"
                v-model="subject"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="message">Your message</label>
              <textarea id="message" class="form-control" rows="6" v-model="message"></textarea>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 text-right">
              <button  type="button"
                @click="submit"
                class="btn btn-primary btn-lg contact-submit"
                :disabled="working || !valid"
                >
                {{ submitStatus }}
              </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API } from 'aws-amplify';

export default {
  name: "App",
  data() {
    return {
      name: null,
      email: null,
      subject: null,
      message: null,
      error: false,
      working: false,
      submitStatus: 'Submit',
      submitted: false
    }
  },
  computed: {
    valid: function() {
      return this.nameValid && this.emailValid && this.message && this.message.length > 1
    },
    nameValid: function() {
      return this.name === null || this.name !== null && this.name.length > 1
    },
    emailValid: function() {
      return this.email === null || this.email !== null && this.email.length > 1
    }
  },
  mounted() {
    console.log('Mounted');
  },
  methods: {
    submit() {
      this.error = false;
      this.working = true;
      this.submitStatus = 'Submitting...';

      console.log('submitting');
      const self = this;

      /* eslint-disable no-undef */
      grecaptcha.ready(function() {
        /* eslint-disable no-undef */
        grecaptcha.execute('6LfTIiEaAAAAAPmOC6frCeqhwMRsVSmCfCj5uISN', {action: 'submit'})
        .then(function(token) {
            return API
              .post('therouxplumbingapi', '/contact', {
                body: {
                  name: self.name,
                  email: self.email,
                  subject: self.subject,
                  message: self.message,
                  token: token
                }
              })
        })
        .then(response => {
          self.submitted = true;
          self.working = false;
          self.submitStatus = 'Submitted!'
          console.log('success', response)
        })
        .catch(error => {
          self.error = true;
          self.working = false;
          self.submitStatus = 'Submit'
          console.log('error', error);
        });
      });  
    }
  }
};
</script>

<style></style>
